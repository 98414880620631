import { useRouter } from "next/router";
import { useEffect } from "react";
import { referralManager } from "~utils/localStorage/referralManager";
import { useGetProfileByIdQuery, useGetProfileByNameQuery } from ".";

const useReferrer = () => {
  const router = useRouter();
  const { query, isReady } = router;

  const { data: profile } = useGetProfileByNameQuery(query.username);

  const { data: referrerProfile } = useGetProfileByIdQuery(query.r, {
    skip: !query.r,
  });

  // Logic if referral id in query
  useEffect(() => {
    if (!isReady || !query.r) return;

    if (profile?.address && referrerProfile?.address) {
      referralManager.setReferralToStorage(
        profile.address,
        referrerProfile.address,
      );
    }
  }, [isReady, profile, referrerProfile]);
};

export default useReferrer;
