import Button from "~components/ui/button";
import P from "~components/ui/typography/p";
import getFormattedPrice from "~utils/vanilla/formatters/get-formatted-price";

const SubscriptionPreviewCard = ({
  title,
  image,
  index,
  availableSupply = 1,
  discount = 50,
  price = 12,
  limited = false,
  isAlreadySubscribed,
  onClick,
}) => {
  const defaultTitle = `Level ${index + 1}`;

  return (
    <div
      className={`min-w-[190px] bg-white border border-[#F5F5F5] shadow-base hover:shadow-large rounded-[18px] py-6 px-11 flex flex-col justify-between items-center cursor-pointer ${
        isAlreadySubscribed ? "opacity-50" : ""
      }`}
      onClick={onClick}
    >
      <P className="@fts text-18px leading-22px mb-5 truncate w-full text-center">
        {title || defaultTitle}
      </P>
      {image && (
        <div className="w-[100px] h-[100px] overflow-hidden rounded">
          <img alt={`${title || defaultTitle} Pic`} src={image} />
        </div>
      )}
      <div className="flex flex-col items-center mt-auto">
        {isAlreadySubscribed ? (
          <Button
            className="@bdc bg-zinc-300 @ttc text-black"
            disabled
            variant="rounded"
          >
            <P>Subscribed</P>
          </Button>
        ) : (
          <>
            {limited ? (
              <P className="@ftf font-clash @ftw font-medium @ttc text-pink-base @fts text-12px @leh leading-15px">
                {availableSupply} slot{availableSupply % 10 !== 1 && "s"} left
              </P>
            ) : (
              <div className="h-[15px]" />
            )}

            {discount ? (
              <div className="flex items-start relative pr-5">
                <P className="@ftf font-clash @ftw font-normal @fts text-12px @ttc text-zinc-900 line-through mt-1 mr-1">
                  ${getFormattedPrice(price)}
                </P>
                <P className="@ftf font-clash @ftw font-semibold @fts text-[20px] @ttc text-zinc-900 leading-[27px]">
                  ${getFormattedPrice(price - (price * discount) / 100)}
                </P>
                <P className="@ftf font-clash @ftw font-bold @fts text-[10px] @ttc text-white @pg py-[2px] px-1 rounded-full absolute left-[80%] top-0 @bdc bg-pink-base">
                  -{discount}%
                </P>
              </div>
            ) : (
              <P className="@ftf font-clash @ftw font-semibold @fts text-[20px] @ttc text-black leading-[27px]">
                ${getFormattedPrice(price)}
              </P>
            )}

            <P className="@fts text-[14px] @oy opacity-20">month</P>
          </>
        )}
      </div>
    </div>
  );
};

export default SubscriptionPreviewCard;
