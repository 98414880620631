import { useRouter } from "next/router";
import { useEffect } from "react";
import { useGetProfileByNameQuery, useLazyGetProfileByIdQuery } from ".";

const useRedirectToNewUsername = () => {
  const router = useRouter();
  const { query, push, isReady, replace } = router;
  const referrerQuery = query.r ? `r=${query.r}` : "";

  const { data: profile, isFetching } = useGetProfileByNameQuery(
    query.username,
  );

  const [trigger] = useLazyGetProfileByIdQuery();

  useEffect(() => {
    if (isFetching || !isReady) return;

    if (!profile && query.u) {
      trigger(query.u).then(({ data: newProfile }) =>
        newProfile
          ? replace(`/${newProfile.username}?${referrerQuery}`)
          : push(`/${query.username}?${referrerQuery}`),
      );
    }
  }, [isReady, isFetching, profile]);
};

export default useRedirectToNewUsername;
