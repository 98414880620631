import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useDiscountInfo, useSubscriptions } from "src/hooks/web3/use-contract";

import H4 from "~components/ui/typography/h4";
import { useGetSubscriptionLevelsQuery } from "~services/backend/subscription-levels";
import { setSubscribeModal } from "~store/modals";
import { getSocialImagePath } from "~utils/vanilla/formatters/get-social-image-path";
import SubscriptionPreviewCard from "./SubscriptionPreviewCard";

const SubscriptionsPreview = ({ profile }) => {
  const dispatch = useDispatch();

  const { data: levels = [] } = useGetSubscriptionLevelsQuery(profile.address);
  const { currentSubscriptions } = useSubscriptions(profile.address);
  const discount = useDiscountInfo({ author: profile.address });

  const subscriptionsInUse = useMemo(
    () =>
      currentSubscriptions ? currentSubscriptions.map((sub) => sub.level) : [],
    [currentSubscriptions],
  );

  const activeLevels = levels.filter((level) => level.isActive);

  const openModal = () => dispatch(setSubscribeModal({ show: true, profile }));

  if (!activeLevels?.length) return null;

  return (
    <>
      <H4 className="@fts text-14px @ftw font-semibold @leh leading-18px">
        Subscription tiers
      </H4>

      <div className="py-4 px-[1px] lg:px-0 w-full overflow-x-scroll xl:overflow-x-visible flex justify-start items-stretch gap-4 ">
        {activeLevels.map((level, index) => (
          <SubscriptionPreviewCard
            isAlreadySubscribed={subscriptionsInUse?.includes(index)}
            key={level.id || index}
            index={index}
            title={level.title}
            image={level.image && getSocialImagePath(level.image)}
            availableSupply={level.availableSupply}
            discount={discount}
            price={level.price}
            limited={level.limited}
            onClick={openModal}
          />
        ))}
      </div>
    </>
  );
};

export default SubscriptionsPreview;
