import Link from "next/link";
import H1 from "~components/ui/typography/h1";
import P from "~components/ui/typography/p";

export default function ErrorMessage({ statusCode }) {
  return (
    <div className="w-full py-20 flex flex-col items-center justify-center text-center">
      {ContentByCode[statusCode] || ContentByCode.default}
    </div>
  );
}

const ContentByCode = {
  404: (
    <>
      <H1 className="@mn mb-3">404 | Page Not Found</H1>
      <P>
        Visit{" "}
        <Link href={"/"} passHref>
          <a className="text-pink-base">the main page</a>
        </Link>
      </P>
    </>
  ),
  default: (
    <>
      <H1 className="@mn mb-3">Ooooops! Something went wrong!</H1>
      <P>Try to reload the page or visit that page later</P>
    </>
  ),
};
