import Image from "next/image";
import React from "react";
import P from "~ui/typography/p";
import H4 from "~ui/typography/h4";
import { formatAddress } from "~utils/transformers";

const NoPostsYet = ({ username, isMyProfile }) => {
  return (
    <div className="flex flex-col justify-center items-center mt-10 mb-20">
      <div className="relative rotate-[15px]">
        <Image
          alt="Wallet not connected"
          src="/assets/images/pencil.png"
          width={80}
          height={80}
          quality={100}
          objectFit="cover"
        />
      </div>

      <H4 className="@ftf font-clash @ftw font-semibold @leh leading-[22px] @fts text-[18px] lg:text-[20px] text-zinc-base mt-5 text-center">
        {isMyProfile
          ? "You don't have any posts yet"
          : `@${formatAddress(username)} has no posts yet`}
      </H4>
      <P className="@ftf font-montserrat @ftw font-medium @leh leading-[20px] @fts text-[14px] text-zinc-base mt-3">
        {isMyProfile ? "Create your first post" : "We will be waiting..."}
      </P>
    </div>
  );
};

export default NoPostsYet;
