import ErrorMessage from "~components/page-blocks/error-block";
import { useRouter } from "next/router";
import FeedCard from "~components/feed/feed-card";
import FeedForm from "~components/feed/feed-form";
import ProfileHeader from "~components/page-blocks/profile-header";
import TagsCloud from "~components/tags-cloud";
import ReferralLink from "~components/referral-link";
import { useSubscriptionListener } from "src/hooks/web3/use-contract";
import wrapper, { makeStore } from "src/store";
import Sidebar from "~components/layout/sidebar";
import AllIcons from "~components/ui/all-icons";
import Widget from "~components/widget";
import P from "~ui/typography/p";
import H4 from "~ui/typography/h4";
import NoPostsYet from "~components/page-blocks/no-posts-yet";
import PlatformReferral from "~components/referral-link/PlatformReferral";
import WidgetCarousel from "~components/WidgetCarousel";
import useProfile from "~services/backend/profiles/useProfile";
import {
  profilesApi,
  getProfileByName,
  useGetProfileByNameQuery,
  useLazyGetProfileByIdQuery,
  getVerifiedProfiles,
} from "~services/backend/profiles";
import {
  getProfilePosts,
  postsApi,
  useGetProfilePostsQuery,
  useLazyGetProfilePostsQuery,
} from "~services/backend/posts";
import useReferrer from "~services/backend/profiles/useReferrer";
import useRedirectToNewUsername from "~services/backend/profiles/useRedirectToNewUsername";
import { useEffect } from "react";
import SubscriptionsPreview from "~components/SubscriptionsPreview/SubscriptionsPreview";
import { Virtuoso } from "react-virtuoso";
import { useDispatch } from "react-redux";

const categories = [];

const Profile = () => {
  const { query } = useRouter();

  const dispatch = useDispatch();
  const { profile: contextProfile } = useProfile();
  const { data: profile } = useGetProfileByNameQuery(query.username);
  const {
    data: posts,
    isLoading: postsAreLoading,
    isFetching: postsAreFetching,
  } = useGetProfilePostsQuery(
    { userId: profile?.id },
    {
      skip: !profile?.id,
    },
  );

  useEffect(() => {
    // refetchProfilePosts();
    // refetchProfileByName();
  }, [contextProfile?.id]);

  const [fetchUserCb] = useLazyGetProfileByIdQuery();
  const [getProfilePostsCb, getProfilePostsResult, { lastArg: lastFetchArg }] =
    useLazyGetProfilePostsQuery();

  useReferrer();
  useRedirectToNewUsername();

  useSubscriptionListener({
    onUpdate: () => {
      if (!profile?.id) return;
      fetchUserCb(profile.id);
      getProfilePostsCb({ userId: profile.id });
    },
  });

  const loadMore = () => {
    if (lastFetchArg.pagination?.start >= posts.length) return;

    getProfilePostsCb({
      userId: profile.id,
      pagination: {
        start: posts.length,
      },
    });
  };

  useEffect(() => {
    if (!getProfilePostsResult.isSuccess) return;
    const { data } = getProfilePostsResult;

    const updateCb = (draft) => {
      if (!data.length) return;
      if (data[data.length - 1].id === draft[draft.length - 1].id) return;

      data.forEach((post) => draft.push(post));

      return draft;
    };

    dispatch(
      postsApi.util.updateQueryData(
        "getProfilePosts",
        { userId: profile.id },
        updateCb,
      ),
    );
  }, [getProfilePostsResult, postsAreFetching]);

  const isMyProfile =
    contextProfile && profile && contextProfile.id === profile.id;

  const showNoPosts = !postsAreLoading && !posts?.length;

  // TODO: 3D LOGO
  if (!profile) {
    return (
      <div className="w-full min-h-[50vh] flex items-center justify-center mx-auto">
        <div className="w-full animate-pulse  flex items-center justify-center mx-auto">
          <AllIcons name="LogoFull" className="w-48 text-zinc-900" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="lg:hidden w-full mb-5">
        <Sidebar />
      </div>
      <ProfileHeader
        profile={profile}
        posts={posts}
        contextProfile={contextProfile}
        isMyProfile={isMyProfile}
      />
      <div className="flex flex-col lg:flex-row w-full">
        <div className="hidden lg:block w-full lg:max-w-[19%] max-w-60 mb-5 lg:mb-0">
          <Sidebar />
        </div>
        <div className="w-full lg:max-w-[81%] flex flex-col items-start lg:flex-row relative">
          <div className="w-full lg:w-[63%] lg:px-5">
            {isMyProfile ? (
              <FeedForm />
            ) : (
              <SubscriptionsPreview profile={profile} />
            )}

            {postsAreLoading ? (
              <>
                <FeedCard isLoading={true} />
                <FeedCard isLoading={true} />
                <FeedCard isLoading={true} />
              </>
            ) : null}

            {!!posts?.length && (
              <>
                {!isMyProfile && (
                  <H4 className="@fts text-14px @ftw font-semibold @leh leading-18px mb-4">
                    All posts
                  </H4>
                )}

                <Virtuoso
                  style={{ height: "100%", marginBottom: "50px" }}
                  useWindowScroll
                  defaultItemHeight={400}
                  data={posts}
                  overscan={20}
                  endReached={loadMore}
                  itemContent={(index, post) => (
                    <FeedCard
                      post={post}
                      key={post.id}
                      isHidden={post.isHidden}
                    />
                  )}
                />

                {getProfilePostsResult.isLoading || postsAreFetching ? (
                  <H4 className="@ftf font-clash @ftw font-semibold @fts text-2xl lg:text-3xl mb-4 text-center @ae animate-pulse">
                    Loading...
                  </H4>
                ) : null}
              </>
            )}

            {showNoPosts && (
              <NoPostsYet
                username={profile.username}
                isMyProfile={isMyProfile}
              />
            )}
          </div>
          <div className="w-full lg:w-[37%]  shrink-0 sticky top-2 hidden lg:block">
            {profile && contextProfile ? (
              isMyProfile ? (
                <PlatformReferral
                  contextProfile={contextProfile}
                  profile={profile}
                />
              ) : (
                <ReferralLink
                  contextProfile={contextProfile}
                  profile={profile}
                />
              )
            ) : null}
            <TagsCloud tagsList={categories} />
            <Widget title="About Subclub" href="/about">
              <P className="@ftw font-medium @fts text-[14px] @ttc text-zinc-900">
                Subclub is a platform where creators can provide paid
                subscriptions for their fans, store files in an extensive cloud
                storage and get income in cryptocurrency.
              </P>
            </Widget>
            <WidgetCarousel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

export const getStaticProps = wrapper.getStaticProps(
  (store) => async (context) => {
    // try {
    //   const name = context.params?.username;
    //   if (typeof name === "string") {
    //     store.dispatch(getProfileByName.initiate(name));
    //   }

    //   await Promise.all([
    //     ...store.dispatch(
    //       profilesApi.util
    //         .getRunningOperationPromise("getProfileByName", name)
    //         .then((res) => {
    //           if (res?.data?.id) {
    //             store.dispatch(
    //               getProfilePosts.initiate({ userId: res?.data?.id }),
    //             );
    //           }
    //         })
    //         .catch((err) => {
    //           console.error("Error when fetch profile by name", err.message);
    //         }),
    //     ),
    //   ]);

    //   await Promise.all([
    //     ...store.dispatch(profilesApi.util.getRunningOperationPromises()),
    //   ]);
    //   await Promise.all([
    //     ...store.dispatch(postsApi.util.getRunningOperationPromises()),
    //   ]);
    // } catch (err) {
    //   console.log("🚀 ~ err:", err);
    // }

    return {
      props: {},
      revalidate: 30,
    };
  },
);

export async function getStaticPaths() {
  try {
    const store = makeStore();
    const { data: profiles } = await store.dispatch(
      getVerifiedProfiles.initiate(),
    );

    const paths = profiles
      ?.map(
        (profile) =>
          profile.username && {
            params: { username: profile.username },
          },
      )
      .filter(Boolean);

    return { paths: paths || [], fallback: "blocking" };
  } catch (err) {
    console.error(err);
    return { paths: [], fallback: "blocking" };
  }
}
